import { FC, useCallback, useMemo } from 'react';
import RadioButton from '../../shared/form-control/RadioButton';
import { Input, InputStyle } from '../../shared/form-control/Input';
import { useTranslation } from 'react-i18next';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { ProgressFilterOptions, ProgressFilterValue } from '../../../models/TableViewFilters';
import { FilterProps } from './FilterProps';

const ProgressFilter: FC<FilterProps> = ({ columnConfig, selectedTemplateId }) => {
  const { t } = useTranslation(['table-view', 'common']);
  const { filters, setFilters } = useTableViewFilters();

  const filterValue = useMemo(() => {
    return filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as ProgressFilterValue | undefined;
  }, [columnConfig.value, filters, selectedTemplateId]);

  const defaultExact = useMemo(() => filterValue?.exact ?? 0, [filterValue?.exact]);

  const defaultMoreThan = useMemo(() => filterValue?.moreThan ?? 0, [filterValue?.moreThan]);

  const defaultLessThan = useMemo(() => filterValue?.lessThan ?? 0, [filterValue?.lessThan]);

  const defaultBetween = useMemo(
    () => ({
      start: filterValue?.between?.start ?? 0,
      end: filterValue?.between?.end ?? 100,
    }),
    [filterValue?.between?.end, filterValue?.between?.start],
  );

  const updateFilter = useCallback(
    (selectedOption: ProgressFilterOptions, newValue: Partial<ProgressFilterValue> = {}) => {
      const updatedFilter: ProgressFilterValue = {
        selectedOption,
        exact: selectedOption === ProgressFilterOptions.EXACT ? newValue.exact ?? defaultExact : null,
        moreThan: selectedOption === ProgressFilterOptions.MORE_THAN ? newValue.moreThan ?? defaultMoreThan : null,
        lessThan: selectedOption === ProgressFilterOptions.LESS_THAN ? newValue.lessThan ?? defaultLessThan : null,
        between: selectedOption === ProgressFilterOptions.BETWEEN ? newValue.between ?? defaultBetween : null,
      };

      setFilters(columnConfig, selectedTemplateId, updatedFilter);
    },
    [columnConfig, defaultBetween, defaultExact, defaultLessThan, defaultMoreThan, selectedTemplateId, setFilters],
  );

  return (
    <div className="h-64 w-72 space-y-4">
      <RadioButton
        value={filterValue?.selectedOption === ProgressFilterOptions.EXACT}
        size="small"
        label={t(`filters.progress.${ProgressFilterOptions.EXACT}`)}
        onChange={() => updateFilter(ProgressFilterOptions.EXACT)}
      />

      {filterValue?.selectedOption === ProgressFilterOptions.EXACT && (
        <div className="relative flex items-center space-x-2">
          <Input
            type="number"
            value={`${defaultExact}`}
            onChange={(e) => updateFilter(ProgressFilterOptions.EXACT, { exact: parseInt(e.target.value) })}
            style={InputStyle.MINIMAL}
            wrapperClassName="w-28"
          >
            <Input.Slot name="trailing">
              <span className="px-1">%</span>
            </Input.Slot>
          </Input>
        </div>
      )}
      <RadioButton
        value={filterValue?.selectedOption === ProgressFilterOptions.LESS_THAN}
        size="small"
        label={t(`filters.progress.${ProgressFilterOptions.LESS_THAN}`)}
        onChange={() => updateFilter(ProgressFilterOptions.LESS_THAN)}
      />

      {filterValue?.selectedOption === ProgressFilterOptions.LESS_THAN && (
        <div className="relative flex items-center space-x-2">
          <Input
            type="number"
            value={`${defaultLessThan}`}
            onChange={(e) => updateFilter(ProgressFilterOptions.LESS_THAN, { lessThan: parseInt(e.target.value) })}
            style={InputStyle.MINIMAL}
            wrapperClassName="w-28"
          >
            <Input.Slot name="trailing">
              <span className="px-1">%</span>
            </Input.Slot>
          </Input>
        </div>
      )}
      <RadioButton
        value={filterValue?.selectedOption === ProgressFilterOptions.MORE_THAN}
        size="small"
        label={t(`filters.progress.${ProgressFilterOptions.MORE_THAN}`)}
        onChange={() => updateFilter(ProgressFilterOptions.MORE_THAN)}
      />

      {filterValue?.selectedOption === ProgressFilterOptions.MORE_THAN && (
        <div className="relative flex items-center space-x-2">
          <Input
            type="number"
            value={`${defaultMoreThan}`}
            onChange={(e) => updateFilter(ProgressFilterOptions.MORE_THAN, { moreThan: parseInt(e.target.value) })}
            style={InputStyle.MINIMAL}
            wrapperClassName="w-28"
          >
            <Input.Slot name="trailing">
              <span className="px-1">%</span>
            </Input.Slot>
          </Input>
        </div>
      )}
      <RadioButton
        value={filterValue?.selectedOption === ProgressFilterOptions.BETWEEN}
        size="small"
        label={t(`filters.progress.${ProgressFilterOptions.BETWEEN}`)}
        onChange={() => updateFilter(ProgressFilterOptions.BETWEEN, { between: defaultBetween })}
      />
      {filterValue?.selectedOption === ProgressFilterOptions.BETWEEN && (
        <div className="flex items-center space-x-2">
          <Input
            type="number"
            value={`${defaultBetween.start}`}
            onChange={(e) => updateFilter(ProgressFilterOptions.BETWEEN, { between: { ...defaultBetween, start: parseInt(e.target.value) } })}
            style={InputStyle.MINIMAL}
            wrapperClassName="w-28"
          >
            <Input.Slot name="trailing">
              <span className="px-1">%</span>
            </Input.Slot>
          </Input>
          <span>{t('filters.date.and')}</span>
          <Input
            type="number"
            value={`${defaultBetween.end}`}
            onChange={(e) => updateFilter(ProgressFilterOptions.BETWEEN, { between: { ...defaultBetween, end: parseInt(e.target.value) } })}
            style={InputStyle.MINIMAL}
            wrapperClassName="w-28"
          >
            <Input.Slot name="trailing">
              <span className="px-1">%</span>
            </Input.Slot>
          </Input>
        </div>
      )}
    </div>
  );
};

export default ProgressFilter;
